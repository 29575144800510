// i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import cookie from 'vue-cookie'
import { decrypt } from '../utils/crypto'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: cookie.get(process.env.VUE_APP_LOCALE) ? cookie.get(process.env.VUE_APP_LOCALE) : 'en',
  fallbackLocale: cookie.get(process.env.VUE_APP_LOCALE) ? cookie.get(process.env.VUE_APP_LOCALE) : 'en'
})

const loadedLanguages = []
let lastLanguage = {}

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync ({ lang, domain, forceUpdate = false }) {
  if (loadedLanguages.includes(lang) && !forceUpdate) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

  if (Object.entries(lastLanguage).length && lastLanguage.constructor === Object) {
    i18n.setLocaleMessage(cookie.get(process.env.VUE_APP_LOCALE), lastLanguage)
  }

  const details = cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(cookie.get(process.env.VUE_APP_USER))) : null
  if (!domain) domain = details ? details.domain : null

  if (domain) {
    return axios.get(`${process.env.VUE_APP_LOCALE_URL}${domain}/${lang}.json`)
      .then(({ data }) => {
        loadedLanguages.push(lang)
        if (Object.entries(lastLanguage).length === 0 && lastLanguage.constructor === Object) lastLanguage = data
        i18n.setLocaleMessage(lang, data)
        i18n.silentTranslationWarn = true
        setI18nLanguage(lang)
      })
  } else return Promise.resolve()
}
