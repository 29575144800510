import { LOGIN, LOGOUT, LOGININTIALSETUP, UPDATE_USER_TZ } from './actionsTypes'
import { loadLanguageAsync } from '@/lang/config'
import { SET_AUTH, RESET_AUTH, XSETSETUPTOKEN, SET_PROFILE_IMAGE } from './mutationsTypes'
import cookie from 'vue-cookie'
import { api } from '../plugins/axios_settings'
import { decrypt, encrypt } from '../utils/crypto'
const state = {
  isAuthenticated: false,
  showLoader: false,
  authToken: '',
  userDetails: {}
}

const getters = {
  userDetails (state) {
    return state.userDetails
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  },
  systemDetails (state) {
    return {
      themecolor: state.userDetails && state.userDetails.systemconfig && state.userDetails.systemconfig.themecolor ? state.userDetails.systemconfig.themecolor : '#1976d2',
      textcolor: state.userDetails && state.userDetails.systemconfig && state.userDetails.systemconfig.textcolor ? state.userDetails.systemconfig.textcolor : '#FFFFFF'
    }
  }
}

const actions = {
  [LOGIN] ({ commit, dispatch }, model) {
    return new Promise((resolve, reject) => {
      const url = model.isSignupSharepoint ? 'auth/signup_with_sharepoint' : (model.isNormalUserLogin) ? 'auth/login_normal_user' : (model.code) ? 'auth/login_with_sharepoint' : 'auth'
      localStorage.removeItem(`${process.env.VUE_APP_NAME}_isLoginSharepoint`)
      // const url = model.isNormalUserLogin ? 'auth/login_normal_user' : model.code ? 'auth/login_with_sharepoint' : 'auth'
      api.post(url, model).then(response => {
        var date = new Date()
        date.setDate(date.getDate() + 4)
        // eslint-disable-next-line camelcase
        if (!response.data.statuscode) {
          const { _id, name, token, isadmin, departmentid, email, userimage, groups, domain, systemconfig, integrations, allowedfeatures, iscontentadmin, issharepointuser, tenants, sharepointconfigdone, landingpageafterlogin, tenantname, isdomainadmin, issharepointstorage, companyname, dateformat } = response.data
          const user = { _id, name, isadmin, email, departmentid, userimage, groups, domain, systemconfig, integrations, allowedfeatures, iscontentadmin, issharepointuser, tenants, sharepointconfigdone, landingpageafterlogin, tenantname, isdomainadmin, issharepointstorage, companyname, dateformat }
          cookie.set(process.env.VUE_APP_TOKEN, token, { expires: date })
          cookie.set(process.env.VUE_APP_USER, JSON.stringify(encrypt(user)), { expires: date })
          cookie.delete(process.env.VUE_APP_NAME + '_secondary_tokenPWA')
          api.defaults.headers.common.Authorization = `Bearer ${token}`
          api.defaults.headers.common['x-timezone'] = (response.data && response.data.timezone) ? response.data.timezone : 'Europe/Berlin'
          commit(SET_AUTH, { authToken: token, user })
          dispatch('getUsers').then(() => {
            resolve({ _id, isadmin, sharepointconfigdone, landingpageafterlogin })
          })
        } else {
          commit(XSETSETUPTOKEN, response.data.authtoken)
          window.localStorage.setItem(process.env.VUE_APP_CUSTOMERSETUP, JSON.stringify(response.data))
          resolve(response.data)
          // dispatch('getUsers').then(() => {
          //   resolve(response.data)
          // })
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  [LOGININTIALSETUP] ({ commit }, model) {
    var date = new Date()
    date.setDate(date.getDate() + 4)
    const { _id, name, token, isadmin, departmentid, email, userimage, groups, domain, systemconfig, integrations, allowedfeatures, iscontentadmin, issharepointuser, tenants, sharepointconfigdone, tenantname, tenantid, isdomainadmin, issharepointstorage, companyname, dateformat } = model
    const user = { _id, name, isadmin, email, departmentid, userimage, groups, domain, systemconfig, integrations, allowedfeatures, iscontentadmin, issharepointuser, tenants, sharepointconfigdone, tenantname, tenantid, isdomainadmin, issharepointstorage, companyname, dateformat }
    if (!user.dateformat) user.dateformat = 'DD.MM.YYYY'
    cookie.set(process.env.VUE_APP_TOKEN, token, { expires: date })
    cookie.set(process.env.VUE_APP_USER, JSON.stringify(encrypt(user)), { expires: date })
    cookie.delete(process.env.VUE_APP_NAME + '_secondary_tokenPWA')
    localStorage.setItem((`${process.env.VUE_APP_NAME}_${model.tenantname}_tz`), JSON.stringify(model.timezone))
    api.defaults.headers.common.Authorization = `Bearer ${token}`
    api.defaults.headers.common['x-timezone'] = model.timezone
    loadLanguageAsync({ lang: 'en', domain })
    commit(SET_AUTH, { authToken: token, user })
  },
  [LOGOUT] ({ commit }) {
    cookie.delete(process.env.VUE_APP_TOKEN)
    cookie.delete(process.env.VUE_APP_USER)
    cookie.delete(process.env.VUE_APP_LOCALE)
    commit(RESET_AUTH)
  },
  [UPDATE_USER_TZ] ({ state }) {
    return new Promise((resolve, reject) => {
      api.get('users/get_current_user_timezone')
        .then(({ data }) => {
          const user = cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(cookie.get(process.env.VUE_APP_USER))) : null
          localStorage.setItem(`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`, JSON.stringify(data))
          resolve(data)
        })
    })
  }
}

const mutations = {
  [SET_AUTH] (state, user) {
    state.isAuthenticated = true
    state.authToken = user.authToken
    state.userDetails = user.user
  },
  [RESET_AUTH] (state) {
    state.isAuthenticated = false
    state.userDetails = {}
  },
  [XSETSETUPTOKEN] (stateObj, token) {
    api.defaults.headers.common['x-setup-user-token'] = token
  },
  [SET_PROFILE_IMAGE] (stateObj, profileImage) {
    stateObj.userDetails.userimage = profileImage
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
