export default {
  data () {
    return {
      NUMBER: 'number',
      DATE: 'date',
      LIST: 'list',
      FORMAT: 'format',
      FROM: 'from',
      TO: 'to',
      SAVE: 'save',
      UPDATE: 'update',
      UPDATE_CLOSE: 'updateClose',
      STATUS: 'status',
      TYPE: 'type',
      VALUE: 'value',
      SHOW: 'show',
      HIDE: 'hide',
      COMPLETED: 'completed',
      PROPERTY: 'property',
      // DEFAULT MODULES
      MEMBERS: 'Members',
      TICKET: 'Tickets',
      ACCOUNTS: 'Accounts',
      ACCOUNT: 'Account',
      DOCUMENTS: 'Documents',
      NOTES: 'Note',
      CHECKLIST: 'Checklist',
      BOOKING_IMAGE: 'images',
      SALE: 'Sale',
      QUOTE: 'Quote',
      PROJECT: 'Project',
      EVENT: 'Event',
      TASK: 'Task',
      SALES: 'Sale',
      FINANCE: 'Finance',
      CONTACT: 'Contact',
      // TIMER MODULE
      WORKING: 'working',
      NON_WORKING: 'non_working',
      NOT_DELIVERED: 'not_delivered',
      DELIVERED: 'delivered',
      APPROVED: 'approved',
      NOT_APPROVED: 'not_approved',
      PRODUCTFILES: 'productfiles',
      ACTIVITY: 'activities',
      HOURS: 'hours',
      PERMISSIONS: 'permissions',
      // list page
      MAINLIST: 'mainlist',
      SUBMODULELIST: 'submodulelist',
      DEFAULT: 'default',
      MODULE: 'module',
      API: 'api',
      INVOICE: 'invoices',
      SYNCFROMTRIPLETEX: 'sync_from_tripletex',
      SYNCFROMDOUSHARE: 'sync_from_docushare',
      VIEW: 'view',
      MANAGE: 'manage',
      // Allowed features for tenants
      SALES_AND_MARKETING: 5,
      CHECKLISTS: 6,
      TIMER: 10,
      TICKETS: 11,
      TRIPLETEX_INTEGRATION: 20,
      DOCUSIGN_INTEGRATION: 21,
      ACTION_APPROVE_OR_DISAPPROVE: 'approve_or_disapprove',
      ACTION_REVIEW_AND_MAKE_CHANGES: 'review_and_make_changes',
      ACTION_ACKNOWLEDGE: 'acknowledge',
      ACTION_INFORMATION: 'information',
      ESCALATION_ACTION_CANCEL_WORKFLOW: 'cancel_workflow',
      ESCALATION_ACTION_SEND_REMINDER: 'send_reminder',
      NOTIFY_ON_ESCALATION: 'escalation',
      NOTIFY_ON_ANY_PROGRESS: 'any_progress',
      NOTIFY_ON_COMPLETION: 'completion',
      STATUS_CREATED: 'created',
      STATUS_INPROGRESS: 'inprogress',
      STATUS_COMPLETED: 'completed',
      STATUS_CANCELLED: 'cancelled',
      STATUS_ONHOLD: 'onhold',
      STATUS_FORCE_COMPLETED: 'forcecompleted',
      ACTION_APPROVED: 'approved',
      ACTION_DISAPPROVED: 'disapproved',
      ACTION_REVIEWED: 'reviewed',
      ACTION_REJECTED: 'rejected',
      ACTION_ACKNOWLEDGED: 'acknowledged',
      ACTION_GOTIT: 'gotit'
    }
  }
}
