<template>
  <div id="app">
    <v-app id="inspire">
      <template v-if="snackbar">
        <!-- <v-snackbar :color="`${color} darken-1`" v-if="color !== 'apiError'" v-model="snackbar" :timeout="3000"> -->
        <v-snackbar v-if="color !== 'apiError'" v-model="snackbar" :timeout="3000" transition="scale-transition">
          <span class="font-weight-medium"> {{ isException ? text : $t(text) }} </span>
          <template #action="{ attrs }">
            <v-btn text @click="snackbar = false; (snackbarObj.onResolve && snackbarObj.onResolve())" icon v-bind="attrs" x-small>
              <v-icon> mdi-close-circle </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-dialog v-if="color === 'apiError'" v-model="snackbar" max-width="400px" persistent  transition="dialog-top-transition">
          <v-card>
            <!-- <v-sheet class="v-sheet--offset mx-auto rounded-circle" color="error" elevation="12" height="45">
              <v-icon dark class="d-flex pt-2 align-center">mdi-alert-outline</v-icon>
            </v-sheet> -->
            <v-card-title style="background:#ff5252">
              <v-flex class="d-flex align-center">
                <v-icon color="white" dark x-large>mdi-alert-outline</v-icon>
                <span class="pl-4" style="color:white">Error</span>
              </v-flex>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-3">
            <v-flex class="text-center">
              <span class="subtitle-1 font-weight-bold"> {{ isException ? text : $t(text) }} </span>
            </v-flex>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-flex class="text-center">
              <v-btn
                color="primary" small
                @click="snackbar = false"
              >
                Dismiss
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </template>
      <v-main
        :style="$route.path === '/dashboard' ? `background: linear-gradient(180deg, ${$vuetify.theme.dark ? '#181818' : systemDetails.themecolor} 50%, ${$vuetify.theme.dark ? '#1e1e1e' : '#fafafa'} 50%)${systemDetails.themecolor}` : ''"
        :class="[$route.path === '/tenantsavailable' ? 'tenant-background' : $vuetify.theme.dark ? '' : 'app-bg-color', removeTransition ? 'remove-transition':'']"
        v-if="!loadRouter"
      >
        <timer v-if="isAuthenticated && $route.path !== '/stemp' && $route.path === '/dashboard'"></timer>
        <router-view></router-view>
      </v-main>
      <!--Footer windows -->
      <v-footer padless v-if="isAuthenticated && !loadRouter">
        <template v-for="(item, index) in listOfMinimizedModal">
          <v-card outlined shaped width="150px" class="mr-2" color="primary" dark :key="index" v-if="item.isShow">
            <v-card-actions>
              <span class="caption text-truncate" style="max-width: 90px;">{{item.title}}</span>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon  dark @click="restoreDialog(index)">mdi-window-restore</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon  dark @click="removeItem(index)">mdi-window-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-footer>
      <layout v-if="!loadRouter && isAuthenticated && $route.path !== '/tenantsavailable'"></layout>
        <!--Common Loader shown until all initial setup done-->
      <v-container fill-height fluid style="background-color: #f1f2f3;" v-if="loadRouter">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="3" align="center">
            <h3 >{{ $route.path === '/login' || $route.path === '/tenantsavailable' ? 'You are logging in... Please wait' : $t('loadingData') }}</h3>
          </v-col>
          <v-col cols="12" align="center" justify="center" class="pt-0 mt-0">
            <!-- <v-img contain width="200" height="150" :src="require('@/assets/Spin.gif')"></v-img> -->
            <v-img contain width="200" height="150" :src="require(`@/assets/${appName}-spin.gif`)"></v-img>
          </v-col>
        </v-row>
      </v-container>
      <!--Common draft dialog-->
      <div v-if="showFormDialog" class="notes-div" style="top:30%!important">
        <note-pad v-if="showFormDialog" :notesData="formReferences" :fromApp="fromApp"></note-pad>
      </div>
      <!-- New content avaliable via service worker -->
      <v-fade-transition>
        <v-alert prominent v-if="getNewContentAvailable" transition="scale-transition" type="info" class="pa-3 ma-3 newContentAlertBox" dense>
          <span v-if="!['/login', '/tenantsavailable'].includes(currentPath)" class="font-weight-medium">{{ $t('newUpdate') }}</span>
          <span v-else class="font-weight-medium"> New Update avaliable! </span>
          <template #append>
            <v-btn outlined @click="loadUpdateContent" class="text-capitalize"> Refresh </v-btn>
          </template>
        </v-alert>
      </v-fade-transition>
      <!-- Show install popup on iOS devices -->
      <v-dialog v-model="showInstalliOS" max-width="400px" persistent class="mt-0">
        <v-card>
          <v-card-title>
            <v-flex xs11>
              <h3>Install</h3>
            </v-flex>
            <v-flex xs1 class="text-xs-right">
              <v-icon @click="showInstalliOS = false">mdi-close</v-icon>
            </v-flex>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-5">
            <v-layout row wrap>
              <v-flex xs12>
                Install this application on your home screen for quick and easy access when you’re on the go.
              </v-flex>
            </v-layout>
            <v-divider class="mt-3"></v-divider>
            <v-layout>
              <v-flex xs12 class="mt-2 text-xs-center">
                <b>Just tap <v-icon color="#59a7ff">mdi-open-in-app</v-icon> then “Add to Home Screen”</b>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- System Notification -->
      <v-dialog width="400" v-model="notificationDialog" class="ma-0 custom-max-height" :style="`height: ${ windowHeight }px !important`" persistent>
        <v-carousel v-model="currentCarouselIndex" :continuous="false" height="auto" hide-delimiter-background :show-arrows="false"
          @change="carouselItemChanged" class="carousel-container">
          <v-carousel-item v-for="(item, index) in notifications" :key="index" exact-active-class>
            <v-card>
              <v-card-title class="pa-0 blue lighten-1">
                  <v-icon @click="notificationDialog=false" color="#FFFFFF" :style="{'position': 'fixed'}">mdi-close-circle</v-icon>
                <div :style="{'width': '550px', 'height': `100px`, 'display': 'flex', 'align-items': 'center'}" class=" justify-center"><!-- Consider changing carousel next prev top value if changing height (height/2) -->
                  <v-avatar size="60" color="grey lighten-3" :style="{'margin': 'auto', 'border': 'solid' , 'opacity': `${item.showMore ? '0.2' : ''}`}" class="pa-0 mt-16">
                    <template v-if="!textLoader">
                      <v-img v-if="item.iswhatisnew" height="60%" src="./assets/what's-new.svg" contain class="rounded-0 pa-0 ma-0 ml-2"></v-img>
                      <v-img v-else height="150%" src="./assets/systemnotify.gif" contain class="rounded-0 pa-0 ma-0"></v-img>
                    </template>
                    <v-progress-circular v-else color="success" size="32"></v-progress-circular>
                  </v-avatar>
                </div>
              </v-card-title>
              <div class="notificition-message-section ma-3 mb-1 show-more-less-font-app-notification" :class="item.showMore ? 'apply-showmore-transition' : item.message && item.message.length > 150 ? 'apply-showless-transition' : ''"
                :style="{'min-height': '90px !important', 'max-height':`${item.message && item.message.length > 150 && item.showMore ? '150' : ''}px !important`,
                'overflow-x': 'hidden', 'height': '90px'}">
                <div v-html="item.message" :class="item.showMore ? '' : 'mt-6 height'" :id="`notification_item_${index}`"></div>
              </div>
              <v-layout class="pt-2 mb-1" row wrap justify-center>
                <v-flex lg11 md10 xl11 xs10>
                  <v-btn block :elevation="0" class="notification-show-more-btn mt-2" depressed plain :ripple="false" v-if="item.contentHidden" small
                    @click="toggleShowMore(item, index)">
                    <span :style="{color: '#2196F3'}" class="caption font-weight-medium">{{ item.showMore ? `${$t('showLess')}...` : `${$t('showMore')}...` }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-card-actions style="height: 50px;">
                <v-btn color="green" class="text-left font-weight-bold text-lowercase" @click="notificationAction(item)" small plain>
                 <span class="text-truncate" style="max-width:130px"> {{ item.buttonaction ==='close' ? item.buttonlabel : $t('open') }}</span></v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue" class="text-right text-lowercase font-weight-bold" small @click="notificationDialog=false" plain>{{ $t('skip') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-app>
  </div>
</template>
<script>
/* eslint-disable */
import { api, checklistApi } from './plugins/axios_settings'
import { mapGetters } from 'vuex'
import goTo from 'vuetify/lib/services/goto'
import { decrypt } from './utils/crypto'
import eventBus from './main'
// import LogRocket from 'logrocket'
export default {
  data () {
    return {
      showScrollToTop: false,
      text: '',
      snackbar: false,
      isException: false,
      color: '',
      links: [],
      formObj: {},
      formReferences: {},
      showFormDialog: false,
      discardDraftDialog: false,
      selectedDraftIndex: '',
      fromApp: true,
      showInstalliOS: false,
      notificationDialog: false,
      notifications: [],
      currentCarouselIndex: -1,
      windowHeight: window.innerHeight,
      textLoader: false,
      removeTransition: false,
      signalRCheckInterval: null,
      snackbarObj: {
        onResolve: () => {}
      }
    }
  },
  components: {
    'note-pad': () => import('./components/notes/NotePad'),
    Layout: () => import('./components/Layout'),
    'timer': () => import('./components/NavbarTimer')
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'listOfMinimizedModal', 'getsignalRConnection', 'getNewContentAvailable', 'timerData', 'showPageLoader' , 'userDetails', 'listOfModules', 'loadRouter', 'systemDetails', 'getsignalRConnectionId']),
    appName () {
      return process.env.VUE_APP_LOGO_ICONS_FOLDER
    },
    currentPath () {
      return this.$route.path
    }
  },
  watch: {
    getNewContentAvailable (val) {
      if (val) this.loadUpdateContent()
    }
  },
  async mounted () {
    const isAuthenticated = this.$cookie.get(process.env.VUE_APP_TOKEN)
    if (isAuthenticated) await this.$store.dispatch('updateUserTimezone')
    this.init()

    this.setAppHeight()
    this.$eventBus.$on('closeMiniNotePad', (data) => {
      this.closeDialogHandler()
    })
    this.$root.$on('setSignalrAndNotification', data => {
      this.setSignalrAndNotification()
    })
    this.$eventBus.$on('appMinimize', (data) => {
      this.appMinimizeHandler()
    })
    this.$eventBus.$on('appRemove', (data) => {
      this.removeItem()
    })
    this.$eventBus.$on('appMiniClose', (data) => {
      this.saveAndCloseHandler()
    })
    this.$root.$on('snackbar', (data) => {
      this.snackbarObj = data
      this.snackbar = data.snackbar
      this.color = data.color
      this.text = data.text
      this.isException = data.isException
    })
    let showniOSBanner = window.localStorage.getItem(process.env.VUE_APP_INSTALL_BANNER) || false
    if (this.isIos() && !this.isInStandaloneModeIos() && !showniOSBanner) {
      this.showInstalliOS = true
      window.localStorage.setItem(process.env.VUE_APP_INSTALL_BANNER, true)
    } else {
      this.showInstalliOS = false
    }
    this.$root.$on('removeTransition', (data) => {  
      this.removeTransition = data
    })
    this.$root.$on('getSystemNotificationFromTenant', (response) => {
      this.getSystemNotificationHandler(response)
    })
    // check for signalR connectivity
    // this.signalRCheckInterval = setInterval(() => {
    //   if (!this.getsignalRConnectionId && this.userDetails._id) this.$store.dispatch('establishSignalR')
    // }, 2000)
  },
  methods: {
    init () {
      let hasSetCookie = localStorage.getItem('BFLOW_REMOVED_STRICT')
      if (hasSetCookie) {
        let authToken = this.$cookie.get(process.env.VUE_APP_TOKEN)
        let user = this.$cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(this.$cookie.get(process.env.VUE_APP_USER))) : {}
        let authDetails = { authToken, user }
        this.$store.state.common.ACCOUNTS_INDEX = {}
        if (authToken) {
          // this.setSignalrAndNotification()
          this.$api.execute('get', 'systemconfigurations').then(response => {
            if (response.data && response.data.tenant && response.data.tenant.allowedfeatures) {
              let features = response.data.tenant.allowedfeatures
              user.allowedfeatures = features.map(x => x.id)
            }
          }).finally(() => {
            this.$store.commit('setAuth', authDetails)
          })
          this.$store.state.common.loadRouter = true
          this.$store.dispatch('getUserGroups').then(() => {
            this.$store.dispatch('getUsers')
            this.$store.dispatch('getModuleList').then(() => {
              let listOfModules = this.$formatter.cloneVariable(this.listOfModules)
              listOfModules.forEach(element => {
                this.$store.state.common[`${element.name}_INDEX`] = {}
              })
              // this.$store.commit('setFiltersHistory')
              this.$store.dispatch('getSharepointFields')
              this.$store.dispatch('systemNotification').then((response) => {
                this.getSystemNotificationHandler(response)
              })
              this.getWorkspacesOfCurrentUser()
            })
          })
          this.$store.dispatch('getTimerSettings')
        }
      } else {
        this.$cookie.set(process.env.VUE_APP_TOKEN, null)
        this.$store.state.common.loadRouter = true
        this.$store.dispatch('logout')
        this.$router.push('/login')
        this.$store.state.common.loadRouter = false
        localStorage.setItem('BFLOW_REMOVED_STRICT', true)
      }
    },
    setAppHeight () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    isIos () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    },
    isInStandaloneModeIos () {
      return ('standalone' in window.navigator) && (window.navigator.standalone)
    },
    restoreDialog (index) {
      let formReferences = this.listOfMinimizedModal[index]
      this.listOfMinimizedModal[index].isShow = false
      formReferences.index = index
      formReferences.appCloseHandler = this.appCloseHandler
      formReferences.appMinimizeHandler = this.appMinimizeHandler
      this.formReferences = formReferences
      this.showFormDialog = true
    },
    saveAndCloseHandler () {
      this.appMinimizeHandler()
      this.listOfMinimizedModal.splice(this.formReferences.index, 1)
    },
    appMinimizeHandler  () {
      this.showFormDialog = false
      this.listOfMinimizedModal[this.formReferences.index].active = true
      this.listOfMinimizedModal[this.formReferences.index].isShow = true
      this.updateMethod('minimize')
    },
    appCloseHandler () {
      this.discardDraftDialog = true
    },
    removeItem (index) {
      this.listOfMinimizedModal.splice(index, 1)
    },
    updateMethod () {
      let model = {
        _id: this.formReferences._id,
        content: this.formReferences.content,
        relation_id: this.formReferences.relation_id,
        relation_name: this.formReferences.relation_name,
        title: this.formReferences.title,
        user_id: this.formReferences.user_id
      }
      this.$api.saveUpdateHandler('notes', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
      })
    },
    setSignalrAndNotification () {
      try {
        // Notification.requestPermission()
        this.$store.dispatch('establishSignalR')
        this.getsignalRConnection.on('LoadTickets', () => {
          if (this.$route.path === '/tickets') this.$root.$emit('loadTickets')
        })
      } catch (err) {
        console.warn('Unable to set signalr-notifications')
      }
    },
    onScroll () {
      if (window.pageYOffset <= 0) this.showScrollToTop = false
      else this.showScrollToTop = true
    },
    scrollToTop () {
      goTo(0)
    },
    loadUpdateContent () {
      this.$store.dispatch('reloadNewContent')
    },
    getSystemNotificationHandler (notifications) {
      let listOfNotifications = []
      let currentDate = this.$formatter.formatDate(this.$formatter.getCurrentDate(this.userDetails.dateformat), this.userDetails.dateformat, 'YYYY-MM-DD')
      if (notifications.length) {
        notifications.forEach(notification => {
          if ((notification.buttonaction !== 'next' && !notification.isexternallink) || (notification.buttonaction === 'next' && notification.isexternallink)) {
          let startDate = this.$formatter.formatDate(notification.datetodisplayfrom, 'DD.MM.YYYYTHH:mm:ss', 'YYYY-MM-DD')
          if (this.$formatter.getSameorBefore(currentDate, startDate) && (!notification.readusers || (notification.readusers && !notification.readusers.includes(this.userDetails.email)))) {
            if (notification.isvisibletoall) listOfNotifications.push(notification)
            else if (notification.isonlyvisibletoadmin && this.userDetails.isadmin) listOfNotifications.push(notification)
          }
          }
        })
        this.notifications = listOfNotifications
        if (this.notifications.length) this.notificationDialog = true
      }
    },
    carouselItemChanged () {
      let item = this.notifications[this.currentCarouselIndex]
      setTimeout(() => {
        let el = document.getElementById(`notification_item_${this.currentCarouselIndex}`)
        if (el && el.scrollHeight > 90) {
          item.showMore = false
          item.contentHidden = true
          this.$set(this.notifications, this.currentCarouselIndex, item)
        }
      })
    },
    notificationAction (item) {
      this.notifications = this.notifications.filter(x => x._id !== item._id)
      this.$api.execute('put', `systemnotification?id=${item._id}&&userMail=${this.userDetails.email}`).then(() => {
        setTimeout(() => {
          if (item.buttonaction === 'next') window.open(item.urltonavigate, '_blank')
          // if (item.buttonaction === 'next') item.isexternallink ? window.open(item.urltonavigate, '_blank') : window.open(item.urltonavigate, '_self')
        }, 300)
      })
      if (this.notifications.length === 0) this.notificationDialog = false
      },
    toggleShowMore (item, index) {
      item.showMore = !item.showMore
      this.$set(this.notifications, index, item)
    }
    },
  beforeMount () {
    let text = 'Authentication Failed'
    // let apis = [ api, checklistApi ]
    api.interceptors.response.use(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        let exception = error.response
        if (error.response) {
            if (error.response.status === 409) {
            setTimeout(() => {
              window.location.reload()
            })
          } else if (error.response.status === 404) {
            this.$router.push('/dashboard')
        } else {
            if (error.response.data) exception = `Message : ${error.response.data || this.$t('unknownError')}`
            else exception = `Message : ${error.response.statusText || this.$t('unknownError')}`
            this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: JSON.stringify(exception), isException: true })
            if (error.response.status === 401) {
              this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text, isException: true })
              this.$store.dispatch('logout')
              this.$router.push('/login').catch(() => {})
            }
          }
        } else {
          let text = error.message
          this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: JSON.stringify(text), isException: true })
        }
        return Promise.reject(error)
      }
    )
    // let text = this.$t('message.login.authFailed')
    // let apis = [ api, checklistApi ]
    // apis.forEach(element => {
    //   element.interceptors.response.use(
    //     (response) => {
    //       return Promise.resolve(response)
    //     },
    //     (error) => {
    //       this.$store.commit('hideLoader')
    //       if (error.response) {
    //         let exception = error.response
    //         if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
    //         else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
    //         this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception) })
    //         if (error.response.status === 401) {
    //           this.$root.$emit('snackbar', { snackbar: true, color: 'error', text })
    //           this.$store.dispatch('logout')
    //           this.$router.push('/login').catch(() => {})
    //         }
    //       } else {
    //         let text = error.message
    //         this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(text) })
    //       }
    //       return Promise.reject(error)
    //     }
    //   )
    // })
  },
  beforeDestroy () {
    eventBus.$off('new-notification')
    clearInterval(this.signalRCheckInterval)
  },
  destroyed () {
    clearInterval(this.signalRCheckInterval)
    this.$root.$off('snackbar')
    this.$root.$off('appMinimize')
    this.$root.$off('appRemove')
    this.$root.$off('removeTransition')
  }
}
</script>
<style lang="scss">
  @import './assets/css/app.scss';
  .dashboard_bg_color {
    background: linear-gradient(180deg, #1976d2 50%, #ffffff 50%);
  }
  .app-bg-color {
    background: #f7f7f7;
    // background: #f9f9f9;
  }
  /* Added these codes for invent component style */
  .proptable td {
    border: 1px solid #0000001f;
    border-collapse: collapse;
  }
  .dense_table .v-text-field--full-width > .v-input__control > .v-input__slot {
    min-height: auto !important;
    border-style: none !important;
  }
  .dense_table .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0 !important;
    font-size: 14px;
    border-style: none !important;
  }
  .dense_table .v-select > .v-input__control > .v-input__slot {
    padding: 0 !important;
    font-size: 14px;
    border-style: none !important;
  }
  .dense_table .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background: none !important;
  }
  .dense_table .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: transparent !important;
    border-style: unset !important;
  }
  .dense_table .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: unset !important;
  }
  .dense_table:not(.invoiceProducts).v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background: none !important;
  }
  .formRender .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: -18px !important;
  }
  .tenant-background {
    background:linear-gradient(#29323c, #485563)
  }
  .custom-max-height {
  max-height: 99% !important;
  }
  .notificition-message-section {
  margin: 1rem;
  display: flex;
  justify-content: center;
  }
  .notificition-message-section > div.height {
  max-height: 150px !important;
  overflow: hidden;
  }
  // .notificition-message-section > div.height p {
  // margin-bottom: unset !important;
  // }
  .notificition-message-section > * {
  display: flex;
  align-items: center;
  flex-direction: column;
  }
  .carousel-container .v-carousel__controls {
   width: 150px !important;
   left: 28% !important;
   font-size: 11px !important;
  }
  .carousel-container .v-carousel__controls__item {
    margin: unset !important;
  }
  .carousel-container .v-btn > .v-btn__content .v-icon {
    color: blue !important;
  }
  .carousel-container .mdi-close-circle::before {
    position: absolute !important;
    font-size: 25px !important;
    top: -60px !important;
  }
  .carousel-container .mdi-circle::before {
    font-size: x-small !important;
  }
  .apply-showmore-transition {
    transition: 0.5s;
    transform: translateZ(-15px);
    opacity: 10;
  }
  .notification-show-more-btn.v-btn:not(.v-btn--round).v-size--small{
    // background-color: #EEEEEE !important;
    height: 25px !important;
  }
  .carousel-container .v-image__image--contain {
      background-size: cover;
  }
  .carousel-container .caption {
    font-size: 0.60rem !important;
  }
  .carousel-container .font-weight-medium {
    font-weight: 800 !important;
  }
  .container-custom-scroll .container {
  padding: unset !important; //Added for scroll issue in whatsnew page
  }
  // calendar page general css
  .calendar-page .custom-select-input div[role=button] {
    min-height: 36px !important;
    max-height: 36px !important;
  }
  .calendar-page .fc-scroller.fc-day-grid-container {
    // height: calc(100vh - 560px) !important;
    height: unset !important;
    overflow: hidden !important;
  }
  // fc-scroller fc-day-grid-container
  /* Added these codes for invent component style */
  .newContentAlertBox {
    position: fixed;
    bottom: 0;
    z-index: 100;
  }
  .v-application--wrap {
    min-height: 120vh !important;
    min-height: calc(var(--vh, 1vh) * 100) !important;
  }
  .remove-transition {
    -webkit-transition: none !important;
     transition: none !important;
  }
</style>
