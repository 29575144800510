export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const PAGINATION = 'rememberPagination'
export const USEROLES = 'userRoles'
export const DELETE_HANDLER = 'deleteHandler'
export const DELETE_SINGLE_RECORD_HANDLER = 'deleteSingleRecordHandler'
export const USERS = 'getUsers'
export const SHIFTS = 'getShifts'
export const UPDATE_USER_TZ = 'updateUserTimezone'
export const USER_PERMISSION = 'getUserPermissions'
export const DEPARTMENTS = 'getDepartments'
export const USERCOST = 'getUserCost'
export const COMPANIES = 'getCompanies'
export const MAILBOX = 'getMailbox'
export const TICKETSTATUS = 'getTicketStatus'
export const TICKETPRIORITY = 'getTicketPriority'
export const TICKETCATEGORY = 'getTicketCategory'
export const TICKETTYPES = 'getTicketTypes'
export const GROUPS = 'getListOfGroups'
export const MODULE_LIST = 'getModuleList'
export const SHAREPPOINT_LIST = 'getSharepointFields'
export const MINIMIZEDACTION = 'setMinimizedValues'
export const PROJECTS = 'getProjects'
export const WORKTYPES = 'getWorktypes'
export const GET_RUNNING = 'getRunningTimer'
export const GETCURRENTPROJECTS = 'getCurrentProjects'
export const RELOAD_NEW_CONTENT = 'reloadNewContent'
export const CHECKLIST_FORMS = 'getChecklistForms'
export const ACCOUNTS_LIST = 'getAccounts'
export const USER_GROUPS = 'getUserGroups'
export const TIMER_FORM_SETTINGS = 'getTimerFormSettings'
export const TIMTER_SETTINGS = 'getTimerSettings'
export const TIMECODES = 'getTimeCodes'
export const ESTABLISHSIGNALRCONNECTION = 'establishSignalR'
export const LOGININTIALSETUP = 'loginInitalSetup'
export const SYSTEM_NOTIFICATION = 'systemNotification'
