import VueCookie from 'vue-cookie'
import moment from 'moment'
import { decrypt } from '../utils/crypto'

const getUpdatedTimeZone = () => {
  const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
  let savedTimezone = (user) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`)) : null
  savedTimezone = (savedTimezone && savedTimezone !== 'undefined') ? JSON.parse(savedTimezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
  moment.tz.setDefault(savedTimezone)
  return savedTimezone
}
let timezone = getUpdatedTimeZone()

export default {
  mounted () {
    timezone = getUpdatedTimeZone()
  },
  methods: {
    getRunningStemp () {
      this.$store.dispatch('getRunningTimer', this.userDetails._id)
        .then((data) => {
          if (data) {
            const transformNumber = { number: { list: ['overtime50', 'overtime100'], format: 'replaceDotWithComma' } }
            data = this.$formatter.formatModel(data, transformNumber)
            data.toinvoice = data.toinvoice || 0
            this.timerObj = data
            // if (!this.timerObj.projectid) this.timerObj.projectid = 0
            if (this.$route.path === '/stemp') this.setProjectCustomerList()
            if (data.time) {
              this.startTimer(data.time)
              this.isTimerRunning = true
            }
          } else {
            this.$store.commit('clearTimerValue')
            if (this.$route.path === '/stemp') {
              const result = this.getTimeCodes.find(x => x.isdefault)
              if (result && this.getTimerFormSettings && this.getTimerFormSettings.timecode) this.timerObj.timecode = result._id
            }
          }
        })
    },
    startTimer (time) {
      const currentDateTime = moment().valueOf()
      if (time) time = moment.tz(moment(time, 'DD.MM.YYYYTHH.mm.ss'), timezone).valueOf()
      else time = moment().valueOf()
      if (typeof (Worker) !== 'undefined') {
        if (this.webWorkerInstance === null) {
          this.webWorkerInstance = new Worker(`../../js/timer.js?date=${time}&currentDateTime=${currentDateTime}&timezone=${timezone}`)
        }
        this.webWorkerInstance.onmessage = (event) => {
          this.time = event.data
        }
        if (this.$route.path === '/stemp') {
          this.$store.commit('setTimerValue', this.time)
          localStorage.setItem('is_timer_running', true)
          if (!this.timerObj._id) this.saveRecord()
        }
      } else {
        alert('Browser is not supporting web workers!')
      }
    }
  }
}
