export const SET_AUTH = 'setAuth'
export const RESET_AUTH = 'resetAuth'
export const SET_PAGINATION = 'savePagination'
export const SET_LISTVALUES = 'setListValues'
export const SHOW_DELETE_DIALOG = 'showDeleteDialog'
export const HIDE_DIALOG = 'hideDialog'
export const SHOW_LOADER = 'showLoader'
export const HIDE_LOADER = 'hideLoader'
export const SUPPORTING_LIST = 'supportingList'
export const CHANGES_LOST_DIALOG = 'changeLostDialog'
export const IS_CHANGES_MADE = 'isChangesMade'
export const MINIMIZEDMODAL = 'setMinimizedData'
export const REMEMBER_LIST = 'saveFilter'
export const SHAREPPOINT_FIELDS = 'setSharepointFeilds'
export const SET_TIMER_VALUE = 'setTimerValue'
export const CLEAR_TIMER_VALUE = 'clearTimerValue'
export const SET_TIMEROBJ = 'setTimerObj'
export const SHOW_SETTINGS_LOADER = 'showSettingsLoader'
export const HIDE_SETTINGS_LOADER = 'hideSettingsLoader'
export const SITE_NEW_CONTENT = 'siteNewContent'
export const WARNING_CONTENT_SET = 'warningContentSet'
export const SINGANLRENABLED = 'signalREnable'
export const FILTERS_HISTORY_SET = 'setFiltersHistory'
export const XSETSETUPTOKEN = 'xsetuptoken'
export const MULTIPLE_TENANTS = 'multipleTenants'
export const STOP_ROUTE_LOADER = 'stopRouteLoader'
export const START_ROUTE_LOADER = 'startRouteLoader'
export const SET_TENANT_PROFILE = 'setTenantProfile'
export const SET_SYSTEM_NOTIFICATION = 'setSystemNotification'
export const SET_PROFILE_IMAGE = 'setProfileImage'
export const SET_SIGNALR_CONNECTION_ID = 'setSignalRConnectionId'
export const SET_NEW_NOTIFICATIONS_FOUND_VALUE = 'setNewNotificationsFoundValue'
