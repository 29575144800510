import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { i18n, loadLanguageAsync } from './lang/config'
import helper from './mixins/index'
import VueCookie from 'vue-cookie'
import vuetify from './plugins/vuetify'
import vueEventBus from './plugins/events'
import formatter from './plugins/formatters'
import apiServices from './plugins/apiservices'
import moment from './plugins/moment'
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad'
import VueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts'
import ExportingHighcharts from 'highcharts/modules/exporting'
import Sortable from 'sortablejs'
// import AvaliaChecklist from 'avalia_checklist_prod_test'
import constants from './mixins/constants'
import './registerServiceWorker'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import { decrypt } from './utils/crypto'

Vue.config.productionTip = false
ExportingHighcharts(Highcharts)

const plugins = { formatter, VueCookie, vueEventBus, apiServices, moment }
Object.keys(plugins).forEach(key => {
  Vue.use(plugins[key])
})
Vue.use(VueSignaturePad)

const mixins = { helper, constants }
Object.keys(mixins).forEach(key => {
  Vue.mixin(mixins[key])
})
// Vue.use(AvaliaChecklist)
Vue.use(VueHighcharts, { Highcharts })
Vue.use(CKEditor)

const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
let timezone = (user) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`)) : null
timezone = (timezone && timezone !== 'undefined') ? JSON.parse(timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone

axios.defaults.baseURL = process.env.VUE_APP_URL
axios.defaults.headers.common = {
  Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_TOKEN)}`,
  'x-timezone': timezone
}
Vue.directive('sortable', {
  inserted: function (el, binding) {
    // eslint-disable-next-line
    new Sortable(el, binding.value || {})
  }
})

if (process.env.VUE_APP_LOGO_ICONS_FOLDER) {
  const LOGO_FOLDER = 'logofolder'
  const APP_MANIFEST = 'appname'
  const iconTags = document.getElementsByClassName('app-icon')
  const iconTagsList = [...iconTags].filter(x => x.href.includes(LOGO_FOLDER))
  iconTagsList.forEach(x => {
    x.href = x.href.replace(LOGO_FOLDER, process.env.VUE_APP_LOGO_ICONS_FOLDER)
  })
  const manifestElement = document.getElementById('app-manifest')
  if (manifestElement && manifestElement.href.includes(APP_MANIFEST)) manifestElement.href = manifestElement.href.replace(APP_MANIFEST, process.env.VUE_APP_LOGO_ICONS_FOLDER)
}

const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus
export default eventBus

const lang = VueCookie.get(process.env.VUE_APP_LOCALE) || 'en'
loadLanguageAsync({ lang, domain: store.auth ? store.auth.userDetails.domain : '' })
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      vueEventBus,
      render: h => h(App)
    }).$mount('#app')
  })
